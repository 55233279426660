@import "ext/normalize";
@import "syntax";

$linkColor: #0a59b0;
$mobileW: 768px;
$smallMobileW: 480px;
$bigScreenW: 1600px;
$leftWidth: 220px;

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  font-size: 16px;
  line-height: 1.2em;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img,
embed,
object,
video {
  max-width: 100%;
}

a {
  color: #000;
  transition: color 0.3s;
  &:hover {
    color: $linkColor;
  }
}

h2 {
  margin: 0.7em 0;
}
main {
  margin: 0 90px;
  display: flex;
  height: 100%;

  @media screen and (min-width: $bigScreenW) {
    margin: 0 auto;
    max-width: 1440px;
  }
  @media screen and (max-width: 1280px) {
    margin: 0 75px;
  }
  @media screen and (max-width: 1024px) {
    margin: 0 35px;
  }

  @media screen and (max-width: $mobileW) {
    margin: 0 10px;
    flex-direction: column;
  }
  @media screen and (max-width: $smallMobileW) {
    margin: 0;
  }

  .content {
    @media screen and (min-width: $mobileW + 1) {
      max-width: calc(100% - #{$leftWidth});
      margin-left: $leftWidth;
      width: 100%;
    }
  }
  .about {
    display: flex;
    height: 100vh;
    padding-bottom: 15vh;
    min-width: $leftWidth;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: $mobileW) {
      height: auto;
      padding: 10vh 0;
      flex-direction: row;
      padding: 0;
      justify-content: space-between;
      align-items: flex-start;
      margin: 1rem;
      margin-bottom: 3rem;

      svg {
        width: 3rem;
        height: 3rem;
        margin-right: 10px;
      }

      .tagline {
        display: none;
      }

      p {
        display: none;
      }
    }
    @media screen and (min-width: $mobileW + 1) {
      position: fixed;
    }

    h2 {
      font-size: 22px;
      margin-top: 25px;
      margin-bottom: 0;
      color: #222222;
      font-weight: normal;

      @media screen and (max-width: $mobileW) {
        margin-top: 0px;
        line-height: 54px;
      }
    }
    .tagline {
      font-size: 22px;
      margin-top: 17px;
      letter-spacing: -0.6px;
      color: #6c6c6c;
      white-space: pre-wrap;
      line-height: normal;
    }
    svg {
      max-width: 150px;
    }
  }
}
#title {
  font-weight: 300;
  font-size: 2rem;

  a {
    text-decoration: none;
  }

  @media screen and (max-width: $mobileW) {
    font-size: 1.3rem;
  }
}

ul.menu {
    text-align: center;
    padding: 0;
    margin: 0;
    margin-bottom: 40px;

  li {
    list-style-type: none;
    padding: 10px;
    a {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: $mobileW) {
    position: absolute;
    margin-left: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
    width: 100%;
    margin-left: -1rem;

    li a {
      font-size: 0.9rem;
    }
  }
}

.social {
  list-style-type: none;
  padding: 0;
  margin-top: 0;
  li {
    float: left;
    margin-left: 10px;

    a {
      opacity: 0.4;
    }

    a:hover {
      opacity: 0.8;
    }

    svg {
      width: 1.2rem;
    }

  }
}

.navigation {
  width: 80%;

  ul {
    list-style-type: none;
    padding: 0;
    margin-top: 0;

    li:last-child {
      a {
        border-bottom: 0;
      }
    }

    a {
      display: block;
      text-align: center;
      padding: 1rem 0;
      text-decoration: none;
      border-bottom: 1px solid #ececec;

      @media screen and (max-width: $mobileW) {
        padding: 2rem 0;
      }
    }
  }
}

.post-wrapper {
  border-bottom: 1px solid #ececec;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.post-categories {
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    font-size: 0.8rem;
    font-weight: 400;
    color: #393939;
    float: left;
    background-color: #f7f1f1;
    padding: 2px 10px;
    border-radius: 10px;
    @media screen and (max-width: $mobileW) {
      margin-top: 5px;
    }
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
.post-date {
  margin-right: 10px;
  color: #555;
  min-width: 150px;
  font-size: 1rem;
}
.post-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: $mobileW) {
    flex-direction: column-reverse;
    align-items: baseline;
    .post-date {
      margin-bottom: 16px;
    }
  }
}
.post-link {
  text-decoration: none;
}

.post-title {
  line-height: 1.6em;
  font-size: 1.8rem;
  letter-spacing: -1px;
  margin-top: 2rem;

  @media screen and (max-width: $mobileW) {
    font-size: 1.5rem;
  }
}
.posts,
.post-container {
  list-style-type: none;
  margin: 45px 30px;
  @media screen and (max-width: $smallMobileW) {
    margin-left: 20px;
    margin-right: 20px;
  }
  @media screen and (max-width: $mobileW) {
    padding: 0 !important;
    margin-top: 0;
  }
}
.post-container {
  padding-left: 30px;
}
.post {
  font-family: sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
  color: #222;
  line-height: 1.6em;
  a {
    color: $linkColor;
    text-decoration: none;
    transition: border-bottom 0.4s;
    &:hover {
      border-bottom: 0.5px solid $linkColor;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: inherit;
  }
}

#posts-label {
  border-bottom: 1px solid #eee;
  font-size: 15px;
  color: #535252;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding-bottom: 10px;
  font-weight: normal;
}
